import { Modal } from '@consta/uikit/Modal';
import { Button, NumberBox, SelectBox } from "devextreme-react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { exportToExcelBalance } from "./reportBalance";

const months = [
    { id: 1, month: 'Январь' },
    { id: 2, month: 'Февраль' },
    { id: 3, month: 'Март' },
    { id: 4, month: 'Апрель' },
    { id: 5, month: 'Май' },
    { id: 6, month: 'Июнь' },
    { id: 7, month: 'Июль' },
    { id: 8, month: 'Август' },
    { id: 9, month: 'Сентябрь' },
    { id: 10, month: 'Октябрь' },
    { id: 11, month: 'Ноябрь' },
    { id: 12, month: 'Декабрь' },
];


const ReportBalanceParam = (props) => {
    const date = new Date();
    const { isOpen, onClose } = props;
    const [month, setMonth] = useState(date.getMonth() + 1);
    const [year, setYear] = useState(date.getFullYear());
    const [opened, setOpened] = useState(false);


    useEffect(() => {
        setOpened(isOpen);

    }, [isOpen]);

    return (
        <Modal
            hasOverlay
            isOpen={opened}
            onClose={() => { onClose(); }}
            style={{ height: "200px", width: "350px" }}
        >
            <div style={{ display: "flex", flexDirection: "column", }}>
                <div style={{ display: "flex", height: "50px", justifyContent: "space-between", alignItems: "center", color: "steelblue", paddingLeft: "10px", fontSize: "22px", margin: '10px 10px 10px 10px', fontWeight: 600, borderBottom: "1px groove rgb(241, 232, 232)" }}>
                    Параметры отчета
                    <Button stylingMode="text" icon="close" onClick={() => setOpened(false)} />
                </div>

                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'space-around', fontSize: '12px', margin: '8px' }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', flexDirection: 'row', marginBottom: '10px', padding: "0 30px 0 30px" }}>Месяц:
                        <SelectBox
                            dataSource={months}
                            displayExpr="month"
                            valueExpr="id"
                            value={month}
                            width="200px"
                            onValueChanged={(e) => { setMonth(e.value) }}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', flexDirection: 'row', padding: "0 30px 0 30px" }}>Год:
                        <NumberBox
                            width="200px"
                            showSpinButtons
                            value={year}
                            onValueChanged={(e) => { setYear(e.value) }}
                            min={2017}
                            max={undefined}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", paddingRight: "5px", justifyContent: "flex-end", alignItems: "center", height: "50px", borderTop: "1px groove rgb(241, 232, 232)" }}>
                    <Button stylingMode="contained" text="OK" type="success" height="30px" width="80px" onClick={async() => {await exportToExcelBalance({year, month}); setOpened(false)}} />
                    <Button stylingMode='contained' text="Закрыть" height="30px" width="80px" style={{ margin: "0 7px 0 5px" }} onClick={() => setOpened(false)} />
                </div>
            </div>
        </Modal>
    )

}

export default observer(ReportBalanceParam);
