import { LoadPanel } from 'devextreme-react';
import DataGrid, { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import mainStore from './store';

const Invoice = (props) => {
    const { Naklad_ID } = props.data.data;

    const [loading, setLoading] = useState(false);
    const [invoiceSource, setInvoiceSource] = useState([]);

    useEffect(() => {
        setLoading(true);
        
        const load = async () => {
            const arr = await mainStore.getInvoiceSource(Naklad_ID);
            setInvoiceSource(arr);
            setLoading(false);
        }

        load();
    }, [Naklad_ID]);

    return (
        <div style={{ width: "1500px", margin: "0px 10px 0 10px", borderRadius: "3px", background: "linear-gradient(90deg, steelblue, aliceblue)", boxShadow: "-2px -2px 15px 0px darkgray", border: "steelblue groove 1px" }}>
            <LoadPanel
                visible={loading}
                showPane
                shading
                shadingColor="rgba(0,0,0,0.4)"
                message="Загрузка..."
                onHiding={() => setLoading(false)}
            />

            <div style={{ color: "whitesmoke", fontSize: "15px", height: "24px", paddingLeft: "8px", fontWeight: "500", alignItems: "center", display: "flex" }}> Товары </div>
            <DataGrid
                dataSource={invoiceSource}
                keyExpr="Record_ID"
                showBorders
                showColumnLines
                showRowLines
                wordWrapEnabled
                rowAlternationEnabled
                onCellPrepared={e => {
                    if (e.rowType === "header")
                        e.cellElement.style.backgroundColor = "#edf2f7";
                }}
            >
                <Column dataField="Record_ID" caption="№ п/п" width={65} dataType="number" />
                <Column dataField="Goods_Art" caption="Артикул" width={100} />
                <Column dataField="Goods_Name" caption="Товар" width={747} />
                <Column dataField="Count" caption="Кол-во" width={80} dataType="number" />
                <Column dataField="Price" caption="Цена" width={100} format="#,##0.00" />
                <Column dataField="Discount" caption="Скидка" width={100} format="#,##0.00" />
                <Column dataField="Total" caption="Стоимость" width={150} format="#,##0.00" />
                <Column dataField="Delta" caption="Прибыль/убыток" width={150} format="#,##0.00" />
                <Column />

                <Summary>
                    <TotalItem
                        column="Total"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        displayFormat="Итого: {0}"
                    />
                    <TotalItem
                        column="Delta"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        displayFormat="Итого:  {0}"
                    />
                </Summary>
            </DataGrid>
        </div>
    );
}

export default observer(Invoice);
