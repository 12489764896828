import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import 'devextreme/dist/css/dx.fluent.saas.light.compact.css';
import { loadMessages, locale } from "devextreme/localization";
import ruMessages from "devextreme/localization/messages/ru.json";
import "font-awesome/css/font-awesome.min.css";

import { Button, DataGrid, DateBox, LoadPanel, NumberBox, PivotGrid, RadioGroup, SelectBox, TextBox } from "devextreme-react";
import { Column, Format, Grouping, HeaderFilter, Lookup, MasterDetail, Scrolling, Search, Selection, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Export, FieldChooser, FieldPanel, Scrolling as PivotScrolling } from 'devextreme-react/pivot-grid';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { Button as TextBoxButton } from "devextreme-react/text-box";
import config from "devextreme/core/config";
import { exportDataGrid, exportPivotGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import './App.css';
import Buyers from './buyers';
import { confirm } from "./dialogs";
import Invoice from './invoice';
import Naklad from './naklad';
import OtherGoods from './otherGoods';
import ReportBalanceParam from './reportBalanceParam';
import { exportToExcel } from './reportDetail';
import Seller from './seller';
import mainStore from './store';
import Warehouse from './warehouse';

config({
    defaultCurrency: 'RUB'
});

const moment = require('moment');

const interval = [
    { id: 1, text: "Интервал дат" },
    { id: 2, text: "Фиксированный период" }
];

const periodList = [
    { text: "Дней", code: "D" },
    { text: "Месяцев", code: "M" },
    { text: "Лет", code: "Y" }
];

const App = () => {
    loadMessages(ruMessages);
    locale(navigator.language);

    const gridRef = useRef();
    const ostGridRef = useRef();

    const { getMainSource, getStatusSource, getSourceSource, getWareHouseSource, getBuyerSource, applyFilter, getPivotSource, getOtherGoodsSource, getOstatokSource } = mainStore;
    const { mainSource, statusSource, sourceSource, wareHouseSource, buyerSource, pivotSource, ostatokSource } = mainStore;

    const [focusedRowIndex, setFocusedRowIndex] = useState();

    const [radio, setRadio] = useState(2);
    const [spin, setSpin] = useState(1);
    const [start, setStart] = useState(moment().subtract(7, "days").toDate());
    const [end, setEnd] = useState(new Date());
    const [period, setPeriod] = useState("M");
    const [pageIndex, setPageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statusCode, setStatusCode] = useState();
    const { nakladId, setNakladId } = mainStore;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [ostatokDate, setOstatokDate] = useState(new Date());

    const [sellerOpened, setSellerOpened] = useState(false);
    const [buyersOpened, setBuyersOpened] = useState(false);
    const [reportBalanceParamVisible, setReportBalanceParamVisible] = useState(false);
    const [otherGoodsOpened, setOtherGoodsOpened] = useState(false);
    const [warehouseOpened, setWarehouseOpened] = useState(false);
    const [nakladVisible, setNakladVisible] = useState();
    const [nakladTitle, setNakladTitle] = useState("Накладная");
    const [goodsTemplate, setGoodsTemplate] = useState();

    useEffect(() => {
        moment.locale("ru");

        const load = async () => {
            const promises = [
                getMainSource({ spin: 1, period: "M" }),
                getStatusSource(),
                getSourceSource(),
                getBuyerSource(),
                getOstatokSource(new Date()),
                getWareHouseSource(),
                getOtherGoodsSource()
            ];
            await Promise.all(promises);
        }

        load();
    }, []);

    const renderGroup = (data) => {
        return <div style={{ color: "#115c99b6", fontSize: '16px' }}> {data.displayValue} </div>;
    }

    const groupCellTemplate = (el, data) => {
        let contentEl = document.createElement("div");
        let val = new Date(data.value);
        if (val.toString().indexOf('Invalid') >= 0) {
            val = data.value;
        }
        else
            val = moment(data.value).locale("ru").format("DD.MM.YYYY");
        contentEl.innerHTML = `
            <a target="_blank" rel="noreferrer" style="font-size: 14px; font-weight: 600; color: steelblue">
                ${val}
            </a>
        `;
        el.append(contentEl);
    }

    const boldRender = data => <div style={{ fontWeight: 500, textAlign: "left" }}> {data.text} </div>

    const balanceRender = (data) => {
        const color = data.value < 0 ? "red" : "black";
        return <div style={{ color: color, fontWeight: 500 }}> {data.text} </div>
    }

    const getTransoutDisabled = () => {
        const obj = mainSource.find(e => e.Naklad_ID === nakladId);
        return obj?.Balance >= 0 || !focusedRowIndex;
    }

    const getTransinDisabled = () => {
        const obj = mainSource.find(e => e.Naklad_ID === nakladId);
        return obj?.Balance <= 0 || !focusedRowIndex;
    }

    const getBackDisabled = () => {
        const obj = mainSource.find(e => e.Naklad_ID === nakladId);
        return obj?.Status_Code !== "SALE" || !focusedRowIndex;
    }

    const refreshData = async () => {
        setLoading(true);

        const filter = {
            start: radio === 1 ? start : undefined,
            end: radio === 1 ? end : undefined,
            spin: radio === 2 ? spin : undefined,
            period: radio === 2 ? period : undefined,
            goodsTemplate: goodsTemplate
        }

        await applyFilter(filter);

        if (pageIndex === 1)
            await getPivotSource(filter);

        await getOstatokSource(ostatokDate);

        setSelectedRowKeys([]);
        setLoading(false);
    }

    const deleteNaklad = async () => {
        const result = await confirm("Удалить текущую накладную?");
        if (!result) return;

        await mainStore.deleteNaklad(nakladId);
        await refreshData();
    }

    const contextMenuPreparing = e => {
        if (!e.items) e.items = [];

        const itemClick = (item) => {
            setNakladVisible(true);
            setStatusCode(item.itemData.code);
            setNakladTitle(item.itemData.text);
        }

        e.items.push({
            text: "Создать накладную", icon: "fa fa-plus", items: [
                { text: "Загрузка из Excel", code: "IN", icon: "fa fa-file-excel-o", onItemClick: itemClick },
                { text: "Бонус от поставщика", code: "BONUS", icon: "fa fa-gift", onItemClick: itemClick, beginGroup: true, disabled: !focusedRowIndex },
                { text: "Расходный ордер", code: "TRANSOUT", icon: "fa fa-upload", disabled: getTransoutDisabled(), onItemClick: itemClick },
                { text: "Приходный ордер", code: "TRANSIN", icon: "fa fa-download", disabled: getTransinDisabled(), onItemClick: itemClick },
                { text: "Возврат от покупателя", code: "BACK", icon: "fa fa-undo", disabled: getBackDisabled(), onItemClick: itemClick }
            ]
        })
        e.items.push({ text: "Удалить накладную", icon: "fa fa-minus", disabled: !focusedRowIndex, onItemClick: deleteNaklad });
        e.items.push({
            text: "Отчет в Excel", icon: "fa fa-print", beginGroup: true, items: [
                { text: "Отчет по прибыли (сводный)", icon: "fa fa-file-excel-o", onItemClick: () => setReportBalanceParamVisible(true) },
                // await exportToExcelBalance()
                { text: "Отчет по товарам (детальный)", icon: "fa fa-file-excel-o", onItemClick: async () => await exportToExcel() }
            ]
        });
        e.items.push({ text: "Обновить", icon: "fa fa-refresh", beginGroup: true, onItemClick: () => refreshData() });
    }

    const contextMenuOstatokPreparing = e => {
        if (!e.items) e.items = [];
        const itemClick = (item) => {
            setNakladVisible(true);
            setStatusCode(item.itemData.code);
            setNakladTitle(item.itemData.text);
        }

        e.items.push({
            text: "Создать накладную", icon: "fa fa-plus", items: [
                { text: "Перемещение", code: "MOVE", icon: "fa fa-arrow-right", onItemClick: itemClick, disabled: !selectedRowKeys.length },
                { text: "Продажа", code: "SALE", icon: "fa fa-credit-card", onItemClick: itemClick, disabled: !selectedRowKeys.length },
                { text: "Возврат поставщику", code: "TOSRC", icon: "fa fa-undo", onItemClick: itemClick, disabled: !selectedRowKeys.length },
                { text: "Расход в брак", code: "BAD", icon: "fa fa-thumbs-o-down", onItemClick: itemClick, disabled: !selectedRowKeys.length }
            ]
        });
        e.items.push({ text: "Экспорт в Excel", icon: "fa fa-file-excel-o", onItemClick: exportOstatokToExcel });
        e.items.push({ text: "Обновить", icon: "fa fa-refresh", beginGroup: true, onItemClick: () => refreshData() });
    }

    const exportOstatokToExcel = async () => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("остатки");
        await exportDataGrid({ component: ostGridRef.current.instance, worksheet, autoFilterEnabled: true });
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), "remains.xlsx");
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("pivot");
        exportPivotGrid({
            component: e.component,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "MaxSales.xlsx");
            });
        });
    };


    return (
        <div className="App">
            <LoadPanel
                visible={loading}
                showPane
                shading
                shadingColor="rgba(0,0,0,0.4)"
                message="Обновление данных..."
                onHiding={() => setLoading(false)}
            />

            <Theme preset={presetGpnDefault} style={{ height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                        <div style={{ height: `70px`, display: "flex", alignItems: "center", padding: "0 0 0 8px", borderBottom: "1px #f1e8e8 groove" }}>
                            <RadioGroup
                                dataSource={interval}
                                value={radio}
                                valueExpr="id"
                                displayExpr="text"
                                onValueChanged={e => setRadio(e.value)}
                            />
                            {
                                radio === 1
                                    ?
                                    <div style={{ display: "flex", paddingLeft: "20px", alignItems: "center" }}>
                                        <div style={{ marginRight: "5px", fontSize: "12px" }}> С </div>
                                        <DateBox width={120} value={start} onValueChanged={e => setStart(e.value)} />
                                        <div style={{ margin: "0 5px 0 5px", fontSize: "12px" }}> ПО </div>
                                        <DateBox width={120} value={end} onValueChanged={e => setEnd(e.value)} />
                                    </div>
                                    :
                                    <div style={{ display: "flex", paddingLeft: "20px", alignItems: "center" }}>
                                        <NumberBox width="70px" showSpinButtons min={1} value={spin} onValueChanged={e => setSpin(e.value)} />
                                        <div style={{ margin: "0 5px 0 5px" }} />
                                        <SelectBox
                                            items={periodList}
                                            valueExpr="code"
                                            displayExpr="text"
                                            value={period}
                                            onValueChanged={e => setPeriod(e.value)}
                                        />
                                    </div>
                            }
                            <div style={{ marginLeft: "10px" }}>
                                <Button
                                    stylingMode="contained"
                                    type="default"
                                    icon="check"
                                    text="Применить"
                                    size='s'
                                    height="28px"
                                    width="100px"
                                    onClick={refreshData}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "3px", borderBottom: "1px #f1e8e8 groove" }}>
                            <i className="fa fa-search" style={{ marginLeft: "7px", color: "darkslategrey" }} />
                            <div style={{ fontSize: "11px", margin: "0 7px 0 5px" }}> Включает товар: </div>
                            <TextBox
                                width="419px"
                                value={goodsTemplate}
                                onValueChanged={e => setGoodsTemplate(e.value)}
                                placeholder="Артикул или наименование товара"
                            >
                                <TextBoxButton name="clearGoods" location="after" options={{ hint: "Очистить поле", stylingMode: "text", icon: "clear", onClick: () => setGoodsTemplate(null) }} />
                            </TextBox>
                        </div>

                        <TabPanel
                            iconPosition="start"
                            height="calc(100vh - 105px)"
                            // height="100%"
                            showBorders
                            value={pageIndex}
                            className='TABPANEL'
                            itemTitleRender={e =>
                                <>
                                    <i className={e.icon} style={{ fontSize: "15px", color: "darkslategrey", marginRight: "5px" }} />
                                    <div style={{ color: "darkslategrey" }}> {e.title} </div>
                                </>
                            }
                            onSelectedIndexChange={(e) => setPageIndex(e)}
                        >
                            <Item title="Данные" icon="fa fa-database">
                                <DataGrid
                                    // height={`calc(100vh - ${delta})`}
                                    height="100%"
                                    width="100%"
                                    ref={gridRef}
                                    dataSource={mainSource}
                                    showBorders
                                    showColumnLines
                                    showRowLines
                                    wordWrapEnabled
                                    autoExpandAll={false}
                                    keyExpr="Naklad_ID"
                                    focusedRowIndex={focusedRowIndex}
                                    focusedRowKey={nakladId}
                                    onFocusedRowChanging={e => { e.cancel = e.rows[e.newRowIndex]?.rowType === "group" }}
                                    onFocusedRowChanged={e => { setNakladId(e.row.key); setFocusedRowIndex(e.rowIndex) }}
                                    focusedRowEnabled
                                    onCellPrepared={e => {
                                        if (e.rowType === "header") {
                                            e.cellElement.style.backgroundColor = "#edf2f7";
                                            e.cellElement.style.color = "darkslategray";
                                        }
                                    }}
                                    onContextMenuPreparing={contextMenuPreparing}
                                >
                                    <Column dataField="DateCreate" caption="Дата создания" groupIndex={0} sortOrder='desc' visible={false} groupCellTemplate={groupCellTemplate} width={100} />
                                    <Column dataField="Naklad_ID" caption="№ накладной" width={130} cellRender={boldRender} />
                                    <Column dataField="Status_ID" caption="Тип накладной" width={180}>
                                        <Lookup
                                            dataSource={statusSource}
                                            valueExpr="Status_ID"
                                            displayExpr="Status_Name"
                                        />
                                    </Column>
                                    <Column dataField="Source_ID" caption="Поставщик" width={130}>
                                        <Lookup
                                            dataSource={sourceSource}
                                            valueExpr="Source_ID"
                                            displayExpr="Source_Name"
                                        />
                                    </Column>
                                    <Column dataField="Warehouse_ID" caption="Склад" width={100}>
                                        <Lookup
                                            dataSource={wareHouseSource}
                                            valueExpr="Warehouse_ID"
                                            displayExpr="Warehouse_Name"
                                        />
                                    </Column>
                                    <Column dataField="Buyer_ID" caption="Покупатель" width={130}>
                                        <Lookup
                                            dataSource={buyerSource}
                                            valueExpr="Buyer_ID"
                                            displayExpr="Buyer_Name"
                                        />
                                    </Column>
                                    <Column dataField="Naklad_Name" caption="Наименование" width={150} />
                                    <Column dataField="Comm" caption="Комментарий" width={250} />
                                    <Column dataField="NakladSum" caption="Сумма" width={110} dataType="number" format="#,##0.00"> <Format type="currency" /> </Column>
                                    <Column dataField="Total" caption="К оплате" width={150} dataType="number" format="#,##0.00"> <Format type="currency" /> </Column>
                                    <Column dataField="Paid" caption="Оплачено" width={110} dataType="number" format="#,##0.00"> <Format type="currency" /> </Column>
                                    <Column dataField="Balance" caption="Долг" width={110} dataType="number" format="#,##0.00" cellRender={balanceRender}> <Format type="currency" /> </Column>
                                    <Column dataField="Delta" caption="Прибыль/убыток" width={130} dataType="number" format="#,##0.00"> <Format type="currency" /> </Column>
                                    <Column />

                                    <Grouping autoExpandAll={false} />
                                    <Scrolling mode="virtual" useNative />
                                    <MasterDetail enabled component={Invoice} />
                                    <HeaderFilter visible height={500}> <Search enabled /> </HeaderFilter>
                                </DataGrid>
                            </Item>

                            <Item title="Сводная таблица" icon="fa fa-list-alt">
                                <PivotGrid
                                    dataSource={pivotSource}
                                    // height={`calc(100vh - ${delta})`}
                                    height="100%"
                                    width="calc(100vw - 45px)"
                                    showBorders
                                    showRowTotals
                                    showRowGrandTotals
                                    showColumnTotals={false}
                                    showColumnGrandTotals={false}
                                    allowExpandAll
                                    onExporting={onExporting}
                                    onCellPrepared={e => {
                                        switch (e.cell.type) {
                                            case "GT":
                                                e.cellElement.style.fontWeight = 600;
                                                e.cellElement.style.color = "maroon";
                                                break;
                                            case "T":
                                                e.cellElement.style.fontWeight = 500;
                                                e.cellElement.style.color = "black";
                                                break;
                                        }
                                    }}
                                >
                                    <FieldChooser
                                        enabled={false}
                                        height="600px"
                                    />
                                    <FieldPanel
                                        visible={true}
                                        showFilterFields={false}
                                    />

                                    <PivotScrolling mode="virtual" useNative />
                                    <Export enabled={true} />
                                </PivotGrid>
                            </Item>

                            <Item title="Остатки на складах" icon="fa fa-archive">
                                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "5px" }}>
                                        <div style={{ marginRight: "5px", fontWeight: 600, fontSize: "13px" }}> На дату: </div>
                                        <DateBox value={ostatokDate} onValueChanged={async (e) => { setOstatokDate(e.value); await getOstatokSource(e.value); }} />
                                    </div>

                                    <DataGrid
                                        dataSource={ostatokSource}
                                        ref={ostGridRef}
                                        keyExpr="Record_ID"
                                        width="100%"
                                        // height={`calc(100vh - 140px)`}
                                        height="100%"
                                        showBorders
                                        showColumnLines
                                        showRowLines
                                        wordWrapEnabled
                                        autoExpandAll={false}
                                        onFocusedRowChanging={e => { e.cancel = e.rows[e.newRowIndex]?.rowType === "group" }}
                                        onContextMenuPreparing={contextMenuOstatokPreparing}
                                        // focusedRowEnabled
                                        selectedRowKeys={selectedRowKeys}
                                        onSelectionChanged={e => setSelectedRowKeys(e.selectedRowKeys)}
                                        onCellPrepared={e => {
                                            if (e.rowType === "header")
                                                e.cellElement.style.backgroundColor = "#edf2f7";
                                        }}
                                    >
                                        <Column dataField="Warehouse_Name" caption="Склад" groupIndex={0} groupCellTemplate={groupCellTemplate} />
                                        <Column dataField="DateCreate" caption="Дата поступления" dataType="date" width={150} />
                                        <Column dataField="Source_Name" caption="Поставщик" width={170} />
                                        <Column dataField="Goods_Art" caption="Артикул" width={170} />
                                        <Column dataField="Goods_Name" caption="Товар" width={500} />
                                        <Column dataField="Ostatok" caption="Кол-во" width={100} />
                                        <Column dataField="Price" caption="Цена сред" width={150} dataType='number' format="#,##0.00" />
                                        <Column dataField="Total" caption="Стоимость" width={150} dataType='number' format="#,##0.00" />

                                        <Column />

                                        <Scrolling mode="virtual" useNative />
                                        <HeaderFilter visible allowSearch height={500}> <Search enabled /> </HeaderFilter>
                                        <Selection mode="multiple" />

                                        <Summary>
                                            <TotalItem
                                                column="Total"
                                                summaryType="sum"
                                                valueFormat="#,##0.00"
                                                displayFormat="Итого:  {0}"
                                            />
                                            <TotalItem
                                                column="DateCreate"
                                                summaryType="count"
                                                valueFormat="#,##0"
                                                displayFormat="Строк:  {0}"
                                            />
                                        </Summary>

                                    </DataGrid>
                                </div>
                            </Item>
                        </TabPanel>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flexGrow: 0, minWidth: "43px", backgroundColor: "#fff", borderLeft: "#f1e8e8 1px groove" }}>
                        <Button stylingMode="text" style={{ marginTop: "20px" }} hint="Поставщики" width="36px" height="36px" onClick={() => setSellerOpened(true)}>
                            <i className="fa fa-user" aria-hidden="true" style={{ fontSize: "24px", color: "darkorchid" }} />
                        </Button>
                        <Button stylingMode="text" hint="Склады" width="36px" height="36px" onClick={() => setWarehouseOpened(true)}>
                            <i className="fa fa-archive" aria-hidden="true" style={{ fontSize: "24px", color: "cornflowerblue" }} />
                        </Button>
                        <Button stylingMode="text" hint="Покупатели" width="36px" height="36px" onClick={() => setBuyersOpened(true)}>
                            <i className="fa fa-male" aria-hidden="true" style={{ fontSize: "24px", color: "slategray" }} />
                        </Button>
                        <Button stylingMode="text" hint="Виды прочих расходов" width="36px" height="36px" onClick={() => setOtherGoodsOpened(true)}>
                            <i className="fa fa-dollar" aria-hidden="true" style={{ fontSize: "23px", color: "green" }} />
                        </Button>
                    </div>

                    <Seller isOpen={sellerOpened} onClose={() => setSellerOpened(false)} />
                    <Warehouse isOpen={warehouseOpened} onClose={() => setWarehouseOpened(false)} />
                    <Buyers isOpen={buyersOpened} onClose={() => setBuyersOpened(false)} />
                    <OtherGoods isOpen={otherGoodsOpened} onClose={() => setOtherGoodsOpened(false)} />
                </div>

                <Naklad
                    visible={nakladVisible}
                    title={nakladTitle}
                    ownerStatusCode={statusCode}
                    nakladId={nakladId}
                    ownerBuyerId={mainSource.find(e => e.Naklad_ID === nakladId)?.Buyer_ID}
                    refreshData={refreshData}
                    selectedRowKeys={["MOVE", "TOSRC", "SALE", "BAD"].includes(statusCode) ? selectedRowKeys : null}
                    onClose={() => setNakladVisible(false)}
                />

                <ReportBalanceParam isOpen={reportBalanceParamVisible} onClose={() => setReportBalanceParamVisible(false)} />

            </Theme>
        </div>
    );
}

export default observer(App);
