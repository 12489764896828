import { Sidebar } from "@consta/uikit/Sidebar";
import { Button } from "devextreme-react";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { alert, confirm } from './dialogs';
import mainStore from "./store";

const OtherGoods = (props) => {
    const { isOpen, onClose } = props;
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(isOpen);
    }, [isOpen]);

    const onRowRemoving = async (e) => {
        e.cancel = true;
        const r = await confirm("Удалить данную запись?");
        if (!r)
            return;

        const res = await mainStore.deleteOtherGoods(e.data.Goods_ID);
        if (res.message) {
            await alert(res.message);
        }
    }

    const onRowInserted = async (e) => {
        await mainStore.insertOtherGoods({Goods_Name: e.data.Goods_Name});
    }

    const onRowUpdated = async (e) => {
        await mainStore.updateOtherGoods({Goods_Name: e.data.Goods_Name, Goods_ID: e.data.Goods_ID});
    }

    return (
        <Sidebar
            position="right"
            size='l'
            isOpen={opened}
            onClose={() => { setOpened(false); onClose() }}
            style={{ display: "flex", justifyContent: "space-between" }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", width: "100%", height: "100%", borderBottom: "1px #f1e8e8 groove" }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "8px 0 8px 10px", fontSize: "22px", fontWeight: 600, color: "steelblue", borderBottom: "1px #f1e8e8 groove" }}>
                        Прочие расходы
                        <Button stylingMode="text" hint="Закрыть" width="26px" height="26px" onClick={() => setOpened(false)}>
                            <i className="fa fa-close" aria-hidden="true" style={{ fontSize: "18px", color: "gray" }} />
                        </Button>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            dataSource={mainStore.otherGoodsSource}
                            keyExpr="Goods_ID"
                            width="100%"
                            height="100%"
                            showBorders
                            showColumnLines
                            showRowLines
                            onInitNewRow={(e) => e.data.IsOther = true}
                            onRowRemoving={onRowRemoving}
                            onRowInserted={onRowInserted}
                            onRowUpdated={onRowUpdated}
                            onCellPrepared={e => {
                                if (e.rowType === "header") {
                                    e.cellElement.style.backgroundColor = "#edf2f7";
                                    e.cellElement.style.color = "darkslategray";
                                }
                            }}
                        >
                            <Column dataField="Goods_Name" caption="Название" width="100%" />

                            <Editing
                                mode="cell"
                                allowUpdating
                                allowDeleting
                                allowAdding
                                useIcons
                                confirmDelete={false}
                            />
                        </DataGrid>
                    </div>
                </div>
            </div>
        </Sidebar>
    );
}

export default observer(OtherGoods);