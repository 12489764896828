import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import moment from 'moment';
import mainStore from './store';

const
    GRAY = "7F7F7F",
    SKY = "F0F0F0";

export const exportToExcelBalance = async ({year, month}) => {
    const { getReportBalanceSource } = mainStore;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("BalanceDetail",
        {
            pageSetup: { paperSize: 9, orientation: 'landscape', fitToHeight: true },
            views: [{ state: "frozen", ySplit: 1 }]
        });

    worksheet.pageSetup.margins = {
        left: 0.1, right: 0.1,
        top: 0.1, bottom: 0.1,
        header: 0, footer: 0
    };

    worksheet.addRow(["№ накладной", "Дата", "Вид накладной", "Поставщик", "Склад", "Покупатель", "Комментарий", "Баланс", "Долг", "Прибыль"]);
    worksheet.getRow(1).font = {
        size: 10,
        bold: true
    }

    let data = await getReportBalanceSource({year, month});
    data = data.map(e => {
        e.Дата = moment(e.Дата).format("DD.MM.YYYY");
        return Object.values(e);
    })

    worksheet.addRows(data);

    worksheet.autoFilter = {
        from: 'A1',
        to: 'J1',
    }

    for (let i = 0; i <= data.length; i++) {
        const border = { style: 'thin', color: { argb: GRAY } };
        worksheet.getRow(i + 1).eachCell(({ includeEmpty: true }), cell => {
            cell.border = {
                top: border,
                left: border,
                bottom: border,
                right: border
            }
        });
    }

    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.getRow(1).eachCell(cell => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: SKY },
        }
    });

    worksheet.getColumn(2).numFmt = "dd.mm.yyyy";
    worksheet.getColumn(8).numFmt = "#,##0.00";
    worksheet.getColumn(9).numFmt = "#,##0.00";
    worksheet.getColumn(10).numFmt = "#,##0.00";

    worksheet.getColumn(1).width = 14;
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 23;
    worksheet.getColumn(4).width = 13;
    worksheet.getColumn(5).width = 12;
    worksheet.getColumn(6).width = 18;
    worksheet.getColumn(7).width = 26;
    worksheet.getColumn(8).width = 12;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;



    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "ReportBalance.xlsx");
}
