import { Sidebar } from '@consta/uikit/Sidebar';
import { Button, DataGrid } from "devextreme-react";
import { Column, Editing } from 'devextreme-react/data-grid';
import { useEffect, useState } from "react";
import { alert, confirm } from './dialogs';
import mainStore from "./store";

const Warehouse = (props) => {
    const { isOpen, onClose } = props;
    const [opened, setOpened] = useState(true);
    const { wareHouseSource, deleteWareHouse, getWareHouseSource, updateWareHouse, insertWareHouse } = mainStore;

    useEffect(() => {
        setOpened(isOpen);
    }, [isOpen]);


    const onRowInserted = async (e) => {
        await insertWareHouse({
            Warehouse_ID: e.data.Warehouse_Name,
            IsBad: e.data.IsBad,
        });
    }

    const onRowUpdated = async (e) => {
        await updateWareHouse({
            Warehouse_ID: e.data.Warehouse_ID,
            Warehouse_Name: e.data.Warehouse_Name,
            IsBad: e.data.IsBad
        });

        await getWareHouseSource();
    }

    const onRowRemoving = async (e) => {
        e.cancel = true;
        const r = await confirm("Удалить склад?");
        if (!r)
            return;

        const res = await deleteWareHouse(e.data.Warehouse_ID);
        if (!res.message) {
            await getWareHouseSource();
        } else {
            await alert(res.message)
        }
    }


    return (
        <>
            <Sidebar
                position="right"
                size='l'
                isOpen={opened}
                onClose={() => { setOpened(false); onClose() }}
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <div style={{ display: "flex", flexDirection: 'column' }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", width: "100%", borderBottom: "1px #f1e8e8 groove" }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "8px 0 8px 10px", fontSize: "22px", fontWeight: 600, color: "steelblue", borderBottom: "1px #f1e8e8 groove" }}>
                            Склады
                            <Button stylingMode="text" hint="Закрыть" width="26px" height="26px" onClick={() => setOpened(false)}>
                                <i className="fa fa-close" aria-hidden="true" style={{ fontSize: "18px", color: "gray" }} />
                            </Button>
                        </div>
                        <DataGrid
                            dataSource={wareHouseSource}
                            keyExpr="Warehouse_ID"
                            showColumnLines
                            showRowLines
                            onCellPrepared={e => {
                                if (e.rowType === "header") {
                                    e.cellElement.style.backgroundColor = "#edf2f7";
                                    e.cellElement.style.color = "darkslategray";
                                }
                            }}
                            onRowInserted={onRowInserted}
                            onRowUpdated={onRowUpdated}
                            onRowRemoving={onRowRemoving}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating
                                allowDeleting
                                allowAdding
                                useIcons
                                confirmDelete={false}
                            />

                            <Column dataField="Warehouse_Name" caption="Название" />
                            <Column dataField="IsBad" caption="Брак?" dataType="boolean" width={100} alignment="center" />
                        </DataGrid>
                    </div>
                </div>

                {/* <div style={{ display: "flex" }}>
                    <ConstaBtn
                        size="m"
                        view="clear"
                        label="Понятно"
                        width="default"
                        onClick={() => setOpened(false)}
                    />
                    <ConstaBtn
                        size="m"
                        view="ghost"
                        label="Закрыть"
                        width="default"
                        onClick={() => setOpened(false)}
                        style={{ marginLeft: "10px" }}
                    />
                </div> */}
            </Sidebar>
        </>)
}
export default Warehouse;
