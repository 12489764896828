import { Sidebar } from '@consta/uikit/Sidebar';
import { Button, DataGrid } from "devextreme-react";
import { Column, Editing } from 'devextreme-react/data-grid';
import { useEffect, useState } from "react";
import { alert, confirm } from './dialogs';
import mainStore from "./store";


const Buyers = (props) => {
    const { isOpen, onClose } = props;
    const [opened, setOpened] = useState(true);
    const [type, setType] = useState('');
    const [isUr, setUr] = useState(0)
    const { buyerSource } = mainStore;

    useEffect(() => {
        setOpened(isOpen);
    }, [isOpen]);

    const onRowInserted = async (e) => {
        const obj = {
            Buyer_Name: e.data.Buyer_Name,
            Cash: 0,
            IsUr: e.data.IsUr,
        }
        await mainStore.insertBuyerSource(obj);
        await mainStore.getBuyerSource();
    }

    const onRowUpdated = async (e) => {
        const obj = {
            Buyer_Name: e.data.Buyer_Name,
            Buyer_ID: e.data.Buyer_ID,
            Cash: e.data.Cash,
            IsUr: e.data.IsUr,
        }
        await mainStore.updateBuyerSource(obj);
        await mainStore.getBuyerSource();
    }

    const onRowRemoving = async (e) => {
        e.cancel = true;
        const r = await confirm("Удалить покупателя?");
        if (!r)
            return;

        const res = await mainStore.deleteBuyerSource(e.data.Buyer_ID);
        if (!res.message) {
            await mainStore.getBuyerSource();
        } else {
            await alert(res.message)
        }
    }


    const onInitNewRow = (e) => {
        e.data.IsUr = false;
        e.data.Cash = 0;
    }


    return (
        <>
            <Sidebar
                position="right"
                size='l'
                isOpen={opened}
                onClose={() => { setOpened(false); onClose() }}
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <div style={{ display: "flex", flexDirection: 'column' }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", width: "100%", borderBottom: "1px #f1e8e8 groove" }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "8px 0 8px 10px", fontSize: "22px", fontWeight: 600, color: "steelblue", borderBottom: "1px #f1e8e8 groove" }}>
                            Покупатели
                            <Button stylingMode="text" hint="Закрыть" width="26px" height="26px" onClick={() => setOpened(false)}>
                                <i className="fa fa-close" aria-hidden="true" style={{ fontSize: "18px", color: "gray" }} />
                            </Button>
                        </div>
                        <DataGrid
                            dataSource={buyerSource}
                            // id='buyerSource'
                            keyExpr="Buyer_ID"
                            focusedRowEnabled
                            showColumnLines
                            showRowLines
                            onFocusedRowChanged={(e) => { setType(e.row.data.Buyer_Type) }}
                            onRowInserted={onRowInserted}
                            onRowUpdated={onRowUpdated}
                            onRowRemoving={onRowRemoving}
                            onInitNewRow={onInitNewRow}
                            onCellPrepared={e => {
                                if (e.rowType === "header") {
                                    e.cellElement.style.backgroundColor = "#edf2f7";
                                    e.cellElement.style.color = "darkslategray";
                                }
                            }}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating
                                allowDeleting
                                allowAdding
                                useIcons
                                confirmDelete={false}
                            />

                            <Column dataField="Buyer_Name" caption="Название" width={150} alignment="left" />
                            <Column dataField="Cash" caption="Счет" dataType="number" format="#,##0.00" alignment="right" />
                            <Column dataField="IsUr" caption="Юр.лицо?" dataType="boolean" alignment="center" />
                            <Column dataField="Buyer_Type" caption="Тип лица" groupIndex={0} visible={false} alignment="center" groupCellRender={data => <div style={{ color: "#115c99b6", fontWeight: 600, fontSize: '15px' }}> {data.displayValue} </div>} />
                        </DataGrid>
                    </div>
                </div>
            </Sidebar>
        </>)
}
export default Buyers;