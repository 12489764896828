import { Button as ConstaBtn } from "@consta/uikit/Button";
import { Sidebar } from '@consta/uikit/Sidebar';
import { Button, DataGrid } from "devextreme-react";
import { Column, Editing } from 'devextreme-react/data-grid';
import { useEffect, useState } from "react";
import { alert, confirm } from "./dialogs";
import mainStore from "./store";

const Seller = (props) => {
    const { isOpen, onClose } = props;
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(isOpen);
        // const load = async () => {
        //     await mainStore.getSourceSource();
        // }
        // load();
    }, [isOpen]);

    const handleFocusedRowChanged = (row) => {
        // setSupplier(row.row.data);
    }

    const onRowInserted = async (e) => {
        const obj = {
            Source_Name: e.data.Source_Name,
            Source_Code: e.data.Source_Code,
            Cash: 0
        }
        await mainStore.insertSource(obj);
    }

    const onRowUpdated = async (e) => {
        const obj = {
            Source_Name: e.data.Source_Name,
            Source_Code: e.data.Source_Code,
            Source_ID: e.data.Source_ID
        }
        await mainStore.updateSource(obj);
        await mainStore.getSourceSource();
    }

    const onRowRemoving = async (e) => {
        e.cancel = true;
        const r = await confirm("Удалить поставщика?");
        if (!r)
            return;

        const res = await mainStore.deleteSource(e.data.Source_ID);
        if (!res.message) {
            await mainStore.getSourceSource();
        } else {
            await alert(res.message)
        }
    }

    return (
        <Sidebar
            position="right"
            size='l'
            isOpen={opened}
            onClose={() => { setOpened(false); onClose() }}
            //onClickOutside={() => { setOpened(false); onClose(); }}
            style={{ display: "flex", justifyContent: "space-between" }}
        >
            <div style={{ display: "flex", flexDirection: 'column' }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", width: "100%", borderBottom: "1px #f1e8e8 groove" }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "8px 0 8px 10px", fontSize: "22px", fontWeight: 600, color: "steelblue", borderBottom: "1px #f1e8e8 groove" }}>
                        Поставщики
                        <Button stylingMode="text" hint="Закрыть" width="26px" height="26px" onClick={() => setOpened(false)}>
                            <i className="fa fa-close" aria-hidden="true" style={{ fontSize: "18px", color: "gray" }} />
                        </Button>
                    </div>
                    <DataGrid
                        dataSource={mainStore.sourceSource}
                        keyExpr="Source_ID"
                        focusedRowEnabled
                        showColumnLines
                        showRowLines
                        onRowInserted={onRowInserted}
                        onRowUpdated={onRowUpdated}
                        onRowRemoving={onRowRemoving}
                        onFocusedRowChanged={handleFocusedRowChanged}
                        onCellPrepared={e => {
                            if (e.rowType === "header") {
                                e.cellElement.style.backgroundColor = "#edf2f7";
                                e.cellElement.style.color = "darkslategray";
                            }
                        }}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating
                            allowDeleting
                            allowAdding
                            useIcons
                            confirmDelete={false}
                        />
                        <Column dataField="Source_Name" caption="Название" />
                        <Column dataField="Source_Code" caption="Код" width={110} />
                    </DataGrid>
                </div>
            </div>

            <div style={{ display: "flex" }}>
                <ConstaBtn
                    size="m"
                    view="clear"
                    label="Понятно"
                    width="default"
                    onClick={() => setOpened(false)}
                />
                <ConstaBtn
                    size="m"
                    view="ghost"
                    label="Закрыть"
                    width="default"
                    onClick={() => setOpened(false)}
                    style={{ marginLeft: "10px" }}
                />
            </div>
        </Sidebar>
    );
}

export default Seller;
