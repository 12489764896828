import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import moment from 'moment';
import mainStore from './store';

export const exportToExcel = async () => {
    const { mainSource, statusSource, sourceSource, wareHouseSource, buyerSource } = mainStore;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("ReportDetail",
        {
            pageSetup: { paperSize: 9, orientation: "landscape", fitToHeight: true },
            views: [{ state: "frozen", ySplit: 1 }]
        });

    worksheet.pageSetup.margins = {
        left: 0.1, right: 0.1,
        top: 0.1, bottom: 0.1,
        header: 0, footer: 0
    };

    let rowNum = 0;

    worksheet.addRow(["№ накладной", "Тип накладной", "Поставщик", "Склад", "Покупатель", "Наименование", "Комментарий", "Сумма", "К оплате", "Оплачено", "Долг", "Прибыль/убыток"]);
    worksheet.getRow(1).font = {
        size: 11,
        bold: false
    }

    // ОБЩИЙ ЗАГОЛОВОК
    worksheet.getRow(1).height = 23;
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getColumn(1).numFmt = "dd.mm.yyyy";
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell, colNum) => {
        cell.border = {
            top: { style: 'thin', color: { argb: WHITE } },
            left: { style: 'thin', color: { argb: WHITE } },
            bottom: { style: 'thin', color: { argb: WHITE } },
            right: { style: 'thin', color: { argb: WHITE } }
        };
        cell.alignment = { horizontal: "left", vertical: "middle", indent: 1 };
        cell.font = {
            size: 11,
            bold: false,
            color: { argb: WHITE }
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: GRAY }
        };
    });

    let dateCreate = null;
    const groups = [];

    for (let row of mainSource) {
        let vals = [], cell;
        // Дата накладной
        if (row["DateCreate"] !== dateCreate) {
            rowNum = worksheet.addRow([])._number;
            dateCreate = row["DateCreate"];
            cell = worksheet.getCell(rowNum, 1);
            cell.font = {
                size: 14,
                bold: true,
                color: { argb: BLUE }
            }
            cell.numFmt = 'dd.mm.yyyy';
            cell.alignment = { horizontal: "left" }
            cell.value = moment(row["DateCreate"]).format("DD.MM.YYYY");
            worksheet.mergeCells(`A${rowNum}:L${rowNum}`);
        }

        // НАКЛАДНАЯ
        vals = [
            row["Naklad_ID"],
            statusSource.find(el => el.Status_ID === row["Status_ID"])?.Status_Name,
            sourceSource.find(el => el.Source_ID === row["Source_ID"])?.Source_Name,
            wareHouseSource.find(el => el.Warehouse_ID === row["Warehouse_ID"])?.Warehouse_Name,
            buyerSource.find(el => el.Buyer_ID === row["Buyer_ID"])?.Buyer_Name,
            row.Naklad_Name,
            row.Comm,
            row.NakladSum,
            row.Total,
            row.Paid,
            row.Balance,
            row.Delta
        ];

        let nakladRow = worksheet.addRow(vals, "n")._number;

        worksheet.getRow(nakladRow).eachCell({ includeEmpty: true }, (cell, colNum) => {
            cell.font = {
                size: 11,
                bold: false,
                color: { argb: BLACK }
            };
            cell.alignment = { indent: 1 };
            cell.border = {
                top: { style: 'thin', color: { argb: GRAY } },
                left: { style: 'thin', color: { argb: GRAY } },
                bottom: { style: 'thin', color: { argb: GRAY } },
                right: { style: 'thin', color: { argb: GRAY } }
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: SKY },
                // bgColor: { argb: 'FF0000FF' }
            };
        });
        for (let i = 8; i <= 12; i++) {
            worksheet.getCell(nakladRow, i).numFmt = "#,##0.00";
        }

        // ТОВАРЫ
        const arr = await mainStore.getInvoiceSource(row["Naklad_ID"]);
        let firstRow = worksheet.addRow(["Товары"])._number;
        worksheet.mergeCells(`A${firstRow}:L${firstRow}`);
        cell = worksheet.getCell(firstRow, 1);
        cell.font = {
            color: { argb: WHITE },
            size: 12
        }
        cell.alignment = { indent: 1 };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: BLUE }
        };

        rowNum = worksheet.addRow([
            "№ п/п",
            "Артикул",
            "Товар",
            null,
            null,
            null,
            null,
            "Кол-во",
            "Цена сред",
            "Скидка",
            "Стоимость",
            "Прибыль/убыток"
        ], "n")._number;
        worksheet.mergeCells(`C${rowNum}:G${rowNum}`);
        worksheet.getRow(rowNum).eachCell({ includeEmpty: true }, (cell, colNum) => {
            cell.font = {
                size: 10,
                bold: false,
                color: { argb: BLACK }
            };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: SKY },
            };
            cell.alignment = { vertical: "middle", horizontal: "left", indent: 1 };
        });

        for (let r = 0; r < arr.length; r++) {
            const el = arr[r];

            rowNum = worksheet.addRow([
                r + 1,
                el.Goods_Art,
                el.Goods_Name,
                null,
                null,
                null,
                null,
                el.Count,
                el.Price,
                el.Discount,
                el.Total,
                el.Delta
            ], "n")._number;

            for (let i = 9; i <= 12; i++)
                worksheet.getCell(rowNum, i).numFmt = "#,##0.00";
            worksheet.mergeCells(`C${rowNum}:G${rowNum}`);
            worksheet.getRow(rowNum).alignment = { indent: 1 };
            worksheet.getRow(rowNum).font = {
                size: 10,
                bold: false
            };
            worksheet.getCell(rowNum, 1).numFmt = "#,##0";
            worksheet.getCell(rowNum, 8).numFmt = "#,##0";
        };

        cell = worksheet.getCell(nakladRow, 1);
        cell.alignment = { vertical: 'middle' };
        cell.numFmt = "#";

        worksheet.getRow(worksheet.addRow([], "n")._number).height = 10;
        worksheet.mergeCells(`A${rowNum + 1}:L${rowNum + 1}`)

        groups.push({ from: firstRow, to: rowNum + 1 });
    }

    worksheet.properties.outlineProperties = {
        summaryBelow: false,
        summaryRight: false,
    }
    worksheet.getColumn(1).width = 14;
    worksheet.getColumn(2).width = 23;
    worksheet.getColumn(3).width = 14;
    worksheet.getColumn(4).width = 16;
    worksheet.getColumn(5).width = 21;
    worksheet.getColumn(6).width = 17;
    worksheet.getColumn(7).width = 40;
    worksheet.getColumn(8).width = 14;
    worksheet.getColumn(9).width = 14;
    worksheet.getColumn(10).width = 14;
    worksheet.getColumn(11).width = 14;
    worksheet.getColumn(12).width = 18;
    // console.log(groups);

    // СЕТКА И РАМКА ВОКРУГ ТОВАРОВ
    for (let el of groups) {
        for (let i = el.from; i <= el.to; i++) {
            worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell, colNum) => {
                cell.border = {
                    top: { style: "thin", color: { argb: SILVER } },
                    left: { style: "thin", color: { argb: SILVER } },
                    bottom: { style: "thin", color: { argb: SILVER } },
                    right: { style: "thin", color: { argb: SILVER } }
                };
            });

            worksheet.getRow(i).outlineLevel = 1;
        }

        // Заголовок товаров
        worksheet.getRow(el.from + 1).border = {
            top: { style: "thin", color: { argb: SILVER } },
            left: { style: "thin", color: { argb: SILVER } },
            right: { style: "thin", color: { argb: SILVER } },
            bottom: { style: "thin", color: { argb: GRAY } },
        }

        // Левая граница
        for (let i = el.from; i < el.to; i++) {
            const { top, bottom, right } = worksheet.getCell(i, 1).border;

            worksheet.getCell(i, 1).border = {
                top: top,
                bottom: bottom,
                right: right,
                left: { style: "medium", color: { argb: BLUE } }
            }
        }

        // Нижняя граница
        worksheet.getRow(el.to).eachCell({ includeEmpty: true }, cell => {
            const { bottom, left, right } = cell.border;
            cell.border = {
                top: { style: "medium", color: { argb: BLUE } },
                bottom: bottom,
                right: right,
                left: left
            }
        })

        // Правая граница
        for (let i = el.from; i < el.to; i++) {
            const border = { ...worksheet.getCell(i, 12).border };
            const { top, bottom, left } = border;

            worksheet.getCell(i, 12).border = {
                top: top,
                bottom: bottom,
                left: left,
                right: { style: "medium", color: { argb: BLUE } }
            }
        }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "ReportDetail.xlsx");
}

const
    BLUE = "4682b4",
    SILVER = "DBDBDB",
    WHITE = "FFFFFF",
    SKY = "F0F0F0",
    BLACK = "000000",
    GRAY = "7F7F7F";
