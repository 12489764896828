import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';

class MainStore {
    mainSource = [];
    statusSource = [];
    sourceSource = [];
    wareHouseSource = [];
    buyerSource = [];
    invoiceSource = [];
    pivotSource = [];
    ostatokSource = [];
    otherGoodsSource = [];

    nakladId = 0;

    constructor() {
        makeObservable(this, {
            mainSource: observable,
            statusSource: observable,
            sourceSource: observable,
            wareHouseSource: observable,
            buyerSource: observable,
            invoiceSource: observable,
            pivotSource: observable,
            ostatokSource: observable,
            nakladId: observable,
            otherGoodsSource: observable,

            setMainSource: action,
            setStatusSource: action,
            setSourceSource: action,
            setWareHouseSource: action,
            setBuyerSource: action,
            setInvoiceSource: action,
            setPivotSource: action,
            setOstatokSource: action,
            setNakladId: action,
            setOtherGoodsSource: action
        });
    }

    getMainSource = async (filter) => {
        const arr = (await axios.post("/api/data", { filter: filter })).data;
        this.setMainSource(arr);
    }

    getStatusSource = async () => {
        const arr = (await axios.get("/api/status")).data;
        this.setStatusSource(arr);
    }

    getSourceSource = async () => {
        const arr = (await axios.get("/api/source")).data;
        this.setSourceSource(arr);
    }

    getWareHouseSource = async () => {
        const arr = (await axios.get("/api/warehouse")).data;
        this.setWareHouseSource(arr);
    }

    insertWareHouse = async(data) => {
        const res = await axios.post('/api/warehouse', data);
        this.getWareHouseSource();
        return res;
    }

    updateWareHouse = async(data) => {
        await axios.put('/api/warehouse', data)
    }

    deleteWareHouse = async(Warehouse_ID) => {
        const res = (await axios.delete("/api/warehouse", { data: { Warehouse_ID: Warehouse_ID } })).data;
        return res;
    }

    getBuyerSource = async () => {
        const arr = (await axios.get("/api/buyer")).data;
        this.setBuyerSource(arr);
    }

    insertBuyerSource = async(data) => {
        const res = await axios.post('/api/buyer', data);
        this.getBuyerSource();
        return res;
    }

    updateBuyerSource = async(data) => {
        await axios.put('/api/buyer', data)
    }

    deleteBuyerSource = async(Buyer_ID) => {
        const res = (await axios.delete("/api/buyer", { data: { Buyer_ID: Buyer_ID } })).data;
        return res;
    }

    getInvoiceSource = async (naklad_id) => {
        const arr = (await axios.get(`/api/invoice`, { params: { naklad_id: naklad_id } })).data;
        // this.setInvoiceSource(arr);
        return arr;
    }

    insertSource = async (data) => {
        const res = await axios.post('/api/source', data);
        this.getSourceSource();
        return res;
    }

    updateSource = async (data) => {
        await axios.put('/api/source', data)
    }

    deleteSource = async (Source_ID) => {
        const res = (await axios.delete("/api/source", { data: { Source_ID: Source_ID } })).data;
        return res;
    }

    deleteNaklad = async (nakladId) => {
        const res = (await axios.delete("/api/data", { data: { nakladId: nakladId } })).data;
        return res === "OK";
    }

    insertNaklad = async (data) => {
        try {
            const res = await axios.put("/api/data", data);
            return res.data;
        } catch (err) {
            return err.response.data;
        }
    }

    getPivotSource = async (filter) => {
        const arr = (await axios.post("/api/pivot", { filter: filter })).data;
        const data = {
            store: arr,
            fields: [
                { caption: "Кол-во", dataField: "Count", area: "data", dataType: "number", summaryType: "sum", format: "#,##0" },
                { caption: "Цена", dataField: "Price", area: "data", dataType: "number", summaryType: "sum", format: "#,##0.00" },
                { caption: "Скидка", dataField: "Discount", area: "data", summaryType: "sum", format: "#,##0.00" },
                { caption: "Сумма", dataField: "Total", area: "data", summaryType: "sum", format: "#,##0.00" },
                { caption: "Прибыль", dataField: "Delta", area: "data", summaryType: "sum", format: "#,##0.00" },
                { caption: "Год", dataField: "DateCreate", dataType: "date", area: "column", groupInterval: "year" },
                { caption: "Месяц", dataField: "DateCreate", dataType: "date", area: "column", groupInterval: "month" },
                { caption: "Тип накладной", dataField: "Status_Name", area: "row", width: 180 },
                { caption: "Склад", dataField: "Warehouse_Name", area: "row", width: 150 },
                { caption: "Товар", dataField: "Goods_Name", area: "row", width: 350 },
            ]
        }

        this.setPivotSource(data);
    }

    getBonus = async (sourceId, date) => {
        const arr = (await axios.get("/api/bonus", { params: { sourceId: sourceId, date: date } })).data;
        return arr.bonus;
    }

    getOstatokSource = async (date) => {
        const arr = (await axios.get("/api/ostatok", { params: { date: date } })).data;
        this.setOstatokSource(arr);
    }

    applyFilter = async (filter) => {
        await this.getMainSource(filter);
    }

    getReportBalanceSource = async(data) => {
        const arr = (await axios.post("/api/reportBalance", data)).data;
        // this.setReportBalanceSource(arr);
        return arr;
    }

    getOtherGoodsSource = async () => {
        const arr = (await axios.get("/api/otherGoods")).data;
        this.setOtherGoodsSource(arr);
        return arr;
    }

    deleteOtherGoods = async (goodsId) => {
        const res = (await axios.delete("/api/otherGoods", { data: { Goods_ID: goodsId } })).data;
        await this.getOtherGoodsSource();
        return res === "OK";
    }

    insertOtherGoods = async (data) => {
        const res = await axios.post("/api/otherGoods", data);
        await this.getOtherGoodsSource();
        return res === "OK";
    }

    updateOtherGoods = async (data) => {
        const res = await axios.put("/api/otherGoods", data);
        await this.getOtherGoodsSource();
        return res === "OK";
    }

    setStatusSource = (data) => this.statusSource = [...data];
    setMainSource = (data) => this.mainSource = [...data];
    setSourceSource = (data) => this.sourceSource = [...data];
    setWareHouseSource = (data) => this.wareHouseSource = [...data];
    setBuyerSource = (data) => this.buyerSource = [...data];
    setInvoiceSource = (data) => this.invoiceSource = [...data];
    setPivotSource = (data) => this.pivotSource = { ...data };
    setOstatokSource = (data) => this.ostatokSource = [...data];
    setNakladId = (data) => this.nakladId = data;
    setOtherGoodsSource = (data) => this.otherGoodsSource = [...data];
}

const mainStore = new MainStore();

export default mainStore;
